import React from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionContainer, SectionEyebrow } from '../../components/_all' 
import { BlogPostsGrid } from '../../components/blog/_all' 
import './blogGrid.scss'

const BlogGrid = ( { payload } ) => {
  const { id, style, number, title, blogGrid:properties} = payload
  const {language} = useI18next();
  const {t} = useTranslation();
  const cssPrefix = 'ss-blog-grid'

  const blogPostGridOptions = {
    showCategories: true,
    language: language
  }

  //<BlogPostsGrid posts={posts} />
  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        <SectionEyebrow title={t(title)} styles='dark-blue'/>
        <div className='content'>
          <BlogPostsGrid options={blogPostGridOptions}/>
        </div>
      </div>
    </SectionContainer>
  )
}

export default BlogGrid