import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Image, SectionContainer, SectionEyebrow } from '../../components/_all' 
import './grid.scss'

const Grid = ( { payload } ) => {
  const { id, style, number, title, grid:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = 'ss-grid'

  const renderItems = properties.items.map( (item, index) => {
    return (
      <div className='item-wrapper' key={index}>
        <div className={`${cssPrefix}--${style}__image-container`}>
          <Image src={item.image.path} alt={item.image.alt} />
        </div> 
        <div className={`${cssPrefix}--${style}__title`}>
          <h3>{t(item.heading)}</h3>    
        </div>
        <div className={`${cssPrefix}--${style}__content`}>
          <p>{t(item.content)}</p>
        </div>
      </div>
    )
  })

  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        <SectionEyebrow number={number} title={title} styles='dark-blue'/>
          <div className={`${cssPrefix}--${style}__wrapper`}>
            {renderItems}
          </div>
      </div>
    </SectionContainer>
  )
}

export default Grid