import React, { useState } from 'react'
import Form  from 'react-bootstrap/Form';
import Button  from 'react-bootstrap/Button';
import Alert  from 'react-bootstrap/Alert';
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionEyebrow } from '../../components/_all' 
import * as Icons from '../../assets/contact-us/_all'
import './contact-us.scss'

const ContactUs = ( { payload } ) => {
  const { id, style, number, title, contactUs:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = `ss-contact-us--${style}`
  
  const [showAlert, setShowAlert] = useState(false);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("Thank you for contacting us");

  const schema = Yup.object({
    firstName: Yup.string()
      .max(15, t('Must be 15 characters or less'))
      .required(t('This field is required')),
    lastName: Yup.string()
      .max(15, t('Must be 15 characters or less'))
      .required(t('This field is required')),
    email: Yup.string()
      .email(t('Invalid email address'))
      .required(t('This field is required')),
    company: Yup.string()
      .max(50, t('Must be 50 characters or less'))
      .required(t('This field is required')),
    projectdescription: Yup.string()
      .max(500, t('Must be 500 characters or less'))
      .required(t('This field is required')),
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const renderOtherWays = () => {
    if( properties.showOtherWays ) {
      return (
        <div className={`${cssPrefix}__other-ways-container`}>
          <div className={`${cssPrefix}__other-ways-heading`}>
            <h3>{t(properties.otherWays.heading)}</h3>
          </div>
          <div className={`${cssPrefix}__other-ways-list-container`}>
              {renderOtherWaysItems}
          </div>   
        </div>
      )
    }
  }

  const renderOtherWaysItems = properties.otherWays.list.map( (item, index)  => {
    
    const Icon = Icons[item.icon];
    const internal = item.type === 'internal' ? true : false

    if ( internal ) {
      return (
        <Link key={index} to={item.href} className={`${cssPrefix}__other-ways-item ss-nav-link`}>
          <div className='circle bck-teal'>
            { Icon ?  <Icon /> : null }
          </div>
          <p className='teal'>{t(item.title)}</p>
        </Link>
      )
    } else {
      return (
        <a key={index} href={item.href} target='_blank' rel="noreferrer" className={`${cssPrefix}__other-ways-item ss-nav-link`}>
          <div className='circle bck-teal'>
            { Icon ?  <Icon /> : null }
          </div>
          <p className='teal'>{t(item.title)}</p>
        </a>
      )
    }
  }) 
  
  return ( 
    <div id={`${id}`} className={`section ${cssPrefix}`}>
      <div className='grid-wrapper'>
        <SectionEyebrow number={number} title={title} styles='dark-blue'/>
        <div className={`${cssPrefix}__title`}>
          <h2>{t(properties.heading)}</h2>
        </div> 
        <div className={`${cssPrefix}__content`}>
          <h3>{t(properties.content)}</h3>
        </div> 
        <div className={`${cssPrefix}__form-container`}>
          <Formik
            validationSchema={schema}
            onSubmit={
              (values, actions) => {
                fetch("/", {
                  method: "POST",
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  body: encode({ "form-name": "contact-us", ...values })
                })
                .then(() => {
                  setMessage(t("Thank you for contacting us"))
                  setVariant("success");
                  setShowAlert(!showAlert);
                  actions.resetForm()
                })
                .catch(() => {
                  setMessage(t("There was an error when submitting the form"))
                  setVariant("error");
                  setShowAlert(!showAlert);
                })
                .finally(() => actions.setSubmitting(false))
              }
            }      
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              company: '',
              projectdescription: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit} name="contact-us" data-netlify={true} data-netlify-honeypot="bot-field">
              <Form.Control type="hidden" name="form-name" value="contact-us"/>
              <Form.Control type="hidden" name="bot-field" />
              
              <Form.Group controlId="validationFormik01">
                <Form.Label>{t("First name")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  isInvalid={touched.firstName && errors.firstName}
                />
                <ErrorMessage name="firstName" />
              </Form.Group>
              <Form.Group controlId="validationFormik02">
                <Form.Label>{t("Last name")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  isInvalid={touched.firstName && errors.firstName}
                />
                <ErrorMessage name="lastName" />
              </Form.Group>

              <Form.Group controlId="validationFormik03">
                <Form.Label>{t("E-mail")} (*)</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={touched.email && errors.email}
                />
                <ErrorMessage name="email" />
              </Form.Group>

              <Form.Group controlId="validationFormik04">
                <Form.Label>{t("Company")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  isInvalid={touched.company && errors.company}
                />
                <ErrorMessage name="company" />
              </Form.Group>

              <Form.Group className="input-full" controlId="validationFormik05">
                <Form.Label>{t("Project description")} (*)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3" 
                  type="text"
                  name="projectdescription"
                  value={values.projectdescription}
                  onChange={handleChange}
                  isInvalid={touched.projectdescription && errors.projectdescription}
                />
                <ErrorMessage name="projectdescription" />
              </Form.Group>

              <div className="submit-container">
                <div className="alert-container">
                  <Alert show={showAlert} variant={variant}>
                    {message}
                  </Alert>
                </div>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        </div>
      </div>
      { renderOtherWays() }
    </div>
  )
}

export default ContactUs