import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Image, SectionEyebrow } from '../../components/_all' 
import './titleAndContent.scss'

const TitleAndContent = ( { payload } ) => {
  const { id, style, number, title, titleAndContent:properties} = payload
  const {t} = useTranslation();
  const baseCssPrefix = `ss-titleandcontent`
  const specificCssPrefix = `ss-titleandcontent--${style}`
  
  const renderHeading= () => {
    if (properties.heading !== null) {
      return (
        <div className={`${baseCssPrefix}__title ${specificCssPrefix}__title`}>
          <h2 className='headline2--short'>{t(properties.heading)}</h2>
        </div>
      )
    }
  }
  
  const renderContent = properties.content.map( (content, index) => {
    if (content.text && !content.files) {
      return (
        <div className='content-wrapper' key={index}>
          <h3>{t(content.title)}</h3>
          <p>{t(content.text)}</p>
        </div>
      )
    } else if ( content.files && !content.text)
    {
      const renderFiles = content.files.map((file, index) => {
        return (
          <li key={index}>
            <a href={file.href} target='_blank' rel='noreferrer' className={`${baseCssPrefix}__link`}>{t(file.label)}</a>
          </li>
        )
      });
        
      return (
        <div className='content-wrapper' key={index}>
          <h3>{t(content.title)}</h3>
          { renderFiles }
        </div>
      )
    }
    return (
      <>
      </>
    )
  })

  const renderLogos = properties.logos.map( (logo, index) => {
    return (
      <div className={`${baseCssPrefix}__image-container`} key={index}>
        <Image src={logo.image.path} alt={logo.image.alt} />
      </div> 
    )
  })

  return ( 
    <div id={`${id}`} className={`section ${baseCssPrefix} ${specificCssPrefix}`}>
      <div className='grid-wrapper'>
        <SectionEyebrow number={number} title={title} styles='dark-blue'/>
        { renderHeading() }
        <div className={`${baseCssPrefix}__content ${specificCssPrefix}__content`}>
          { renderContent }
        </div>  
        <div className={`${baseCssPrefix}__logos ${specificCssPrefix}__logos`}>
          { renderLogos }
        </div>  
      </div>
    </div>
  )
}

export default TitleAndContent