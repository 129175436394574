import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionContainer, SectionEyebrow } from '../../components/_all' 
import './successStories.scss'

const SuccessStories = ( { payload } ) => {
  const { id, style, number, title, successStories:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = 'ss-success-stories'

  const renderItems = properties.stories.map( (story, index) => {
    const wrapperStyle = {
      backgroundImage: 'url(' + story.image.path + ')',
    };
    
    return (
      <div className='story-wrapper neutral' style={wrapperStyle} key={index}>
        <div className={`${cssPrefix}--${style}__story-client`}>
          <h3>{t(story.client)}</h3>    
          <p>{t(story.location)}</p>
        </div>
        <div className={`${cssPrefix}--${style}__story-project`}>
          <div className='name'>
            <h4>{t(story.projectName)}</h4>
          </div>
          <div className='description'>
            <p>{t(story.projectDescription)}</p>
          </div>
        </div>
      </div>
    )
  })

  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        <SectionEyebrow number={number} title={title} styles='dark-blue'/>
        <h2 className={`${cssPrefix}--${style}__title`}>{t(properties.heading)}</h2>
        <div className={`${cssPrefix}--${style}__content`}>
          <p>{t(properties.content)}</p>
        </div>
        <div className={`${cssPrefix}--${style}__wrapper`}>
          {renderItems}
        </div>
      </div>
    </SectionContainer>
  )
}

export default SuccessStories