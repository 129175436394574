import React from "react"
import { graphql } from "gatsby"
import Layout from '../layout/layout'
import Head from '../components/head'
import * as Sections from '../sections/_all'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { yamlPage, yamlHeader, yamlFooter } = data
  

/*   const importSectionByType = (sectionType) => {
    console.log('Impoting section... ' + sectionType)
    return import(`../sections/${sectionType}`).catch(() =>
      import(`../sections/Hero`))
  } */

  const renderSections = yamlPage.sections.map( (section, index) => {
    const Section = Sections[section.type];
    return Section ? <Section key={index} payload={section} /> : null
  })

  return (
    <Layout className='max-w1440' headerProps={yamlHeader} footerProps={yamlFooter}>
      <Head title={yamlPage.title}/>
      <div id="sections-container">
        {renderSections}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query getPageInfo($slug: String!) {
        yamlPage(slug: {eq: $slug }) {
            title
            sections {
                type
                id
                style
                number
                title
                ...carousel
                ...hero
                ...grid
                ...successStories
                ...cards
                ...contactUs
                ...headingAndLink
                ...pqrs
                ...titleAndContent
                ...blogGrid
            }
        }

        yamlHeader {
          logo: logo___Image_ {
            path
            alt
          }
          items {
            title
            href
          }
        }

        yamlFooter {
          style
          offices {
            city
            country
            image: image___Image_ {
              path
            }
            details
          }
          links {
            label
            href
            type
          }
          copyright
        }
    }

    #Fragments by section type
    fragment hero on YamlPageSections{
      hero {
        heading
        subheading
        image: image___Image_ {
          path
          alt
        }
      }
    }

    fragment carousel on YamlPageSections{
      carousel {
        items {
          heading
          content
          image: image___Image_ {
            path
            alt
          }
        }
      }
    }

    fragment grid on YamlPageSections{
      grid {
        items {
          heading
          content
          image: image___Image_ {
            path
            alt
          }
        }
      }
    }

    fragment successStories on YamlPageSections{
      successStories {
        heading
        content
        stories {
          client
          location
          projectName
          projectDescription
          image: image___Image_ {
            path
            alt
          }
        }
      }
    }

    fragment cards on YamlPageSections{
      cards {
        media {
          path
          alt
        }
        heading
        content
        cards {
          heading
          image: image___Image_ {
            path
            alt
          }          
        }
        cta {
          text
          link {
            label
            href
          }
        }
      }
    }

    fragment contactUs on YamlPageSections{
      contactUs {
        heading
        content
        showOtherWays
        otherWays {
          heading
          list {
            icon
            title
            type
            href
          }          
        }
      }
    }

    fragment headingAndLink on YamlPageSections{
      headingAndLink {
        heading
        link {
          label
          href        
        }
      }
    }

    fragment pqrs on YamlPageSections{
      pqrs {
        heading
      }
    }

    fragment titleAndContent on YamlPageSections{
      titleAndContent {
        heading
        logos {
          image: image___Image_ {
            path
            alt
          } 
        }
        content {
          title
          text
          files {
            label
            href
          }
        }
      }
    }

    fragment blogGrid on YamlPageSections{
      blogGrid {
        showTags
        numRows
      }
    }
`