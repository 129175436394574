import React from 'react'

const Placeholder = ({ name }) => {
  return (
    <div class={`hero-section hero-section-${name}`}>
      <h1>I am a placeholder - {name}</h1>
    </div>
  )
}

export default Placeholder