import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Image, SectionContainer, SectionEyebrow } from '../../components/_all' 
import './cards.scss'

const Cards = ( { payload } ) => {
  const { id, style, number, title, cards:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = 'ss-cards'

  const renderCTA = () => {
    const link = <a href={properties.cta.link.href}>{t(properties.cta.link.label)}</a>
    const newText = t(properties.cta.text).split('<link>')
    return(
      <p>{newText[0]}{link}</p>
    )
  }

  const renderCards = properties.cards.map( (card, index) => {
    return (
      <div className='card-wrapper' key={index}>
        <div className={`${cssPrefix}--${style}__image-container`}>
          <Image src={card.image.path} alt={card.image.alt} />
        </div> 
        <div className={`${cssPrefix}--${style}__heading`}>
          <p>{t(card.heading)}</p>
        </div>
      </div>
    )
  })

  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        <div className={`${cssPrefix}--${style}__media-container`}>
          <Image src={properties.media.path} alt={properties.media.alt} />
        </div> 
        <div className={`${cssPrefix}--${style}__content-container`}>
          <SectionEyebrow number={number} title={title} styles='dark-blue'/>
          <h2 className={`${cssPrefix}--${style}__title`}>{t(properties.heading)}</h2>
          <div className={`${cssPrefix}--${style}__content`}>
            <p>{t(properties.content)}</p>
          </div>
          <div className={`${cssPrefix}--${style}__cards-wrapper`}>
            {renderCards}
          </div>
          <div className={`${cssPrefix}--${style}__cta`}>
            {renderCTA()}
          </div>
        </div>
      </div>
    </SectionContainer>
  )
}

export default Cards