import React from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet'

import { Image, LangSwitcher, SectionContainer, SectionEyebrow } from '../../components/_all' 
import './hero.scss'

const Hero = ( { payload } ) => {
  const { id, style, title, hero:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = 'ss-hero'
  const srcBckImage = '/images/home/hero/hero-bck.png'

  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <Helmet>
          <link rel="preload" as="image" href={srcBckImage}></link>
      </Helmet>
      <div className='grid-wrapper'>
        <SectionEyebrow title={title} styles='yellow'/>
        <h1 className={`${cssPrefix}--${style}__title neutral`}>{t(properties.heading)}</h1>
        <h3 className={`${cssPrefix}--${style}__subtitle neutral--40`}>{t(properties.subheading)}</h3>
        <div className={`${cssPrefix}--${style}__image-container`}>
          <Image critical className={`${cssPrefix}--${style}__image`} src={properties.image.path} alt={properties.image.alt} />
        </div>
        <LangSwitcher className={`${cssPrefix}--${style}__lang-switcher ss-toggle`} linkStyles='ss-toogle-tab dark-blue' />
      </div>
    </SectionContainer>
  )
}

export default Hero