import React, { useState } from 'react'
import Form  from 'react-bootstrap/Form';
import Button  from 'react-bootstrap/Button';
import Alert  from 'react-bootstrap/Alert';
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionEyebrow } from '../../components/_all' 
import './pqrs.scss'

const PQRS = ( { payload } ) => {
  const { id, style, number, title, pqrs:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = `ss-pqrs--${style}`
  
  const [showAlert, setShowAlert] = useState(false);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("Thank you for contacting us");

  const schema = Yup.object({
    firstName: Yup.string()
      .max(15, t('Must be 15 characters or less'))
      .required(t('This field is required')),
    lastName: Yup.string()
      .max(15, t('Must be 15 characters or less'))
      .required(t('This field is required')),
    phone: Yup.string()
      .matches(/^\d+$/, t('The field should have digits only'))
      .max(15, 'Must be 15 characters or less')
      .required(t('This field is required')),
    email: Yup.string()
      .email(t('Invalid email address'))
      .required(t('This field is required')),
    company: Yup.string()
      .max(50, t('Must be 50 characters or less'))
      .required(t('This field is required')),
    requestType: Yup.string()
      .required(t('This field is required')),
    requestDescription: Yup.string()
      .max(500, t('Must be 500 characters or less'))
      .required(t('This field is required')),
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return ( 
    <div id={`${id}`} className={`section ${cssPrefix}`}>
      <div className='grid-wrapper'>
        <SectionEyebrow number={number} title={title} styles='dark-blue'/>
        <div className={`${cssPrefix}__title`}>
          <h2 className='headline2--short'>{t(properties.heading)}</h2>
        </div> 
        <div className={`${cssPrefix}__form-container`}>
          <Formik
            validationSchema={schema}
            onSubmit={
              (values, actions) => {
                fetch("/", {
                  method: "POST",
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  body: encode({ "form-name": "pqrs", ...values })
                })
                .then(() => {
                  setMessage(t("Thank you for contacting us"))
                  setVariant("success");
                  setShowAlert(!showAlert);
                  actions.resetForm()
                })
                .catch(() => {
                  setMessage(t("There was an error when submitting the form"))
                  setVariant("error");
                  setShowAlert(!showAlert);
                })
                .finally(() => actions.setSubmitting(false))
              }
            }      
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              company: '',
              requestType: '',
              requestDescription: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit} name="pqrs" data-netlify={true} data-netlify-honeypot="bot-field">
              <Form.Control type="hidden" name="form-name" value="pqrs"/>
              <Form.Control type="hidden" name="bot-field" />
              
              <Form.Group controlId="validationFormik01">
                <Form.Label>{t("First name")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  isInvalid={touched.firstName && errors.firstName}
                />
                <ErrorMessage name="firstName" />
              </Form.Group>
              <Form.Group controlId="validationFormik02">
                <Form.Label>{t("Last name")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  isInvalid={touched.firstName && errors.firstName}
                />
                <ErrorMessage name="lastName" />
              </Form.Group>

              <Form.Group controlId="validationFormik03">
                <Form.Label>{t("E-mail")} (*)</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={touched.email && errors.email}
                />
                <ErrorMessage name="email" />
              </Form.Group>

              <Form.Group controlId="validationFormik04">
                <Form.Label>{t("Phone")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  isInvalid={touched.phone && errors.phone}
                />
                <ErrorMessage name="phone" />
              </Form.Group>

              <Form.Group className="input-full" controlId="validationFormik05">
                <Form.Label>{t("Company")} (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  isInvalid={touched.company && errors.company}
                />
                <ErrorMessage name="company" />
              </Form.Group>

              <Form.Group className="input-full" controlId="validationFormik06">
                <Form.Label>{t("Type of Request")} (*)</Form.Label>
                <Form.Control
                  as="select"
                  name="requestType"
                  value={values.requestType}
                  onChange={handleChange}
                  isInvalid={touched.requestType && errors.requestType}>
                  <option value="">{t("--Please choose an option--")} </option>
                  <option value="Suggestion">{t("Suggestion")} </option>
                  <option value="Petition">{t("Petition")} </option>
                  <option value="Complaint">{t("Complaint")} </option>
                  <option value="Claim">{t("Claim")} </option>
                </Form.Control>
                <ErrorMessage name="requestType" />
              </Form.Group>

              <Form.Group className="input-full" controlId="validationFormik07">
                <Form.Label>{t("Request")} (*)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3" 
                  type="text"
                  name="requestDescription"
                  value={values.requestDescription}
                  onChange={handleChange}
                  isInvalid={touched.requestDescription && errors.requestDescription}
                />
                <ErrorMessage name="requestDescription" />
              </Form.Group>

              <div className="submit-container">
                <div className="alert-container">
                  <Alert show={showAlert} variant={variant}>
                    {message}
                  </Alert>
                </div>
                <Button variant="primary" type="submit">
                  {t("Submit")} 
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        </div>
      </div>
    </div>
  )
}

export default PQRS