import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Image, SectionContainer, SectionEyebrow } from '../../components/_all' 
import ProgressBar  from 'react-bootstrap/ProgressBar';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import './carousel.scss'
import LeftArrow from '../../assets/common/chevron-left.inline.svg'
import RightArrow from '../../assets/common/chevron-right.inline.svg'

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews)

const Carousel = ( { payload } ) => {
  const { id, style, number, title, carousel:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = 'ss-carousel'

  const [slideIndex, setSlideIndex] = useState(0)

  const renderItems = properties.items.map( (item, index) => {
    return (
      <div className='slide-wrapper' key={index}>
        <div className={`${cssPrefix}--${style}__title`}>
          <h2>{t(item.heading)}</h2>    
        </div>
        <div className={`${cssPrefix}--${style}__content`}>
          <p>{t(item.content)}</p>
        </div>
        <div className={`${cssPrefix}--${style}__image-container`}>
          <Image src={item.image.path} alt={item.image.alt} />
        </div>    
      </div>
    )
  })

  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        <SectionEyebrow number={number} title={title} styles='dark-blue'/>
        <BindKeyboardSwipeableViews 
          className={`${cssPrefix}--${style}__wrapper`}
          slideClassName={`${cssPrefix}--${style}__slide`}
          index={slideIndex}
          onChangeIndex={(index) => setSlideIndex(index)}>
            {renderItems}
        </BindKeyboardSwipeableViews>
        <div className={`${cssPrefix}--${style}__progress`}>
          <ProgressBar now={ ((slideIndex+1) / properties.items.length) *  100 } />
        </div>
        <div className={`${cssPrefix}--${style}__controls`}>
          <button onClick={() => setSlideIndex(slideIndex -1)} disabled={(slideIndex === 0)}>
            <LeftArrow />
          </button>
          <button onClick={() => setSlideIndex(slideIndex +1)} disabled={(slideIndex === (properties.items.length-1))}>
            <RightArrow />
          </button>
        </div>
      </div>
    </SectionContainer>
  )
}

export default Carousel