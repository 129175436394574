import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import { SectionContainer} from '../../components/_all' 
import './headingAndLink.scss'

const HeadingAndLink = ( { payload } ) => {
  const { id, style, headingAndLink:properties} = payload
  const {t} = useTranslation();
  const cssPrefix = 'ss-heading-and-link'

  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        <h3 className={`${cssPrefix}--${style}__title dark-blue`}>{t(properties.heading)}</h3>
        <Link to={properties.link.href} className={`${cssPrefix}--${style}__link`}>{t(properties.link.label)}</Link>
      </div>
    </SectionContainer>
  )
}

export default HeadingAndLink