import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { useBlogsQuery } from '../../hooks/useBlogsQuery'
import { SectionContainer } from '../../components/_all' 
import Img from "gatsby-image"
import './blogHero.scss'

const BlogHero = ( { payload } ) => {
  const { id, style, number, title, blogHero:properties} = payload
  const {language} = useI18next();
  const {t} = useTranslation();
  const cssPrefix = 'ss-blog-hero'

  const querydata = useBlogsQuery()
  const { allPosts } = querydata;

  const postsByLang = allPosts.nodes.filter(post => post.fields.language === language );
  
  const posts = postsByLang.slice(0,3);

  const renderPosts = posts.map( (post, index) => {
    return (
      <div className='blog-post-wrapper' key={index}>
        <Link to={post.fields.slug} className='link'>
          <div className={`header`}>
            <div className={`cover-image`}>
              <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
            </div>
            <div className={`cover-background`}></div>
            <div className={`info-container`}>
              <div className={`title`}>
                <h4>{post.frontmatter.title}</h4>
                <p>{t('By')}: {post.frontmatter.author}</p>
              </div>
              <div className={`category`}><p>{post.frontmatter.category}</p></div>
            </div>
          </div> 
        </Link>
      </div>
    )
})

  //<BlogPostsGrid posts={posts} />
  return ( 
    <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
      <div className='grid-wrapper'>
        {renderPosts}
      </div>
    </SectionContainer>
  )
}

export default BlogHero